import { FolderOpenOutlined } from '@ant-design/icons';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { DamToolkitObject } from 'client/components/widget-repository/objects/dams/DamToolkitObject';
import { set } from 'client/core/data/set';
import React from 'react';
import { WidgetDossierViewerType } from '../../WidgetDossierViewer.type';
import { WidgetDossierViewerProfile } from '../../WidgetDossierViewerProfile';

export const WidgetActionOpenDamDossier = createToolkitAction({
  name: 'Apri Fascicolo Diga',
  code: 'open-dam-dossier',
  icon: <FolderOpenOutlined />,
  arguments: [
    {
      type: [DamToolkitObject],
      name: 'Fascicolo Diga da Aprire'
    },
    {
      type: [WidgetToolkitObject],
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetDossierViewerType),
      name: 'Widget Visualizza Fascicolo'
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    object: ToolkitObject,
    targetObject: ToolkitObject | null
  ) {
    const dam = DamToolkitObject.get(object)!;
    const target = WidgetToolkitObject.get(targetObject);

    const data: WidgetDossierViewerProfile = {
      dossierId: null,
      dossierName: dam.name,
      loadFromDossierName: dam.data.id
    };

    // Se esiste il target, aggiorniamo il widget
    if (target) {
      const widget = ctx.deskContext.widgets.find(w => w.id === target.id)!;
      await ctx.deskContext.updateWidget(set(widget, 'profile.data', data));
      return;
    }

    // Cerco un widget configurato con folium o folium-multiente
    const foliumWidget = ctx.deskContext.widgets.find(
      w =>
        w.documentaryCode === 'folium' ||
        w.documentaryCode === 'folium-multiente'
    );

    if (foliumWidget) {
      await ctx.deskContext.cloneWidget(
        {
          type: 'floating',
          expanded: true,
          order: DeskWidgetsLogic.getFloatingNextOrder(
            ctx.deskContext.floatingWidgets
          )
        },
        WidgetDossierViewerType,
        foliumWidget,
        {
          data,
          quickClose: true,
          initializeInFullScreen:
            ctx.sourceWidget?.profile?.showChildWidgetInFullScreen ?? false
        }
      );
      return;
    }

    // Se non esiste il widget folium o folium-multiente, creiamo un nuovo widget
    await ctx.deskContext.createWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetDossierViewerType,
      {
        data,
        quickClose: true,
        initializeInFullScreen:
          ctx.sourceWidget?.profile?.showChildWidgetInFullScreen ?? false
      }
    );
    return;
  }
});
